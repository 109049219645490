import app from "./app.png";

function Hero() {
  return (
    <div className="max-w-5xl w-full flex flex-row align-middle items-center mt-8 md:mt-20 lg:mt-24">
      <div className="w-full flex-col text-white ">
        <h1 className="text-center text-3xl md:text-4xl lg:text-6xl mb-2 md:mb-4">
          A <span>better</span> focus mode
        </h1>
        <h2 className="text-center text-xl md:text-2xl lg:text-3xl text-yellow-400 px-10 md:px-">
          End doom scrolling and reclaim your life from digital chaos
        </h2>
        <div className="flex flex-col items-center justify-center mt-8 lg:mt-16">
          <div className="flex flex-row">
            <a
              href="https://apps.apple.com/gb/app/focusmode2/id6736573372"
              target="_blank"
              rel="noreferrer"
              className="bg-black hover:bg-gray-900 text-white px-6 py-3 rounded-xl flex items-center transition-colors"
            >
              <svg
                className="w-8 h-8 mr-2"
                viewBox="0 0 24 24"
                fill="currentColor"
              >
                <path d="M18.71 19.5c-.83 1.24-1.71 2.45-3.05 2.47-1.34.03-1.77-.79-3.29-.79-1.53 0-2 .77-3.27.82-1.31.05-2.3-1.32-3.14-2.53C4.25 17 2.94 12.45 4.7 9.39c.87-1.52 2.43-2.48 4.12-2.51 1.28-.02 2.5.87 3.29.87.78 0 2.26-1.07 3.81-.91.65.03 2.47.26 3.64 1.98-.09.06-2.17 1.28-2.15 3.81.03 3.02 2.65 4.03 2.68 4.04-.03.07-.42 1.44-1.38 2.83M13 3.5c.73-.83 1.94-1.46 2.94-1.5.13 1.17-.34 2.35-1.04 3.19-.69.85-1.83 1.51-2.95 1.42-.15-1.15.41-2.35 1.05-3.11z" />
              </svg>
              <div className="flex flex-col">
                <span className="text-xs">Download on the</span>
                <span className="text-xl font-semibold -mt-1">App Store</span>
              </div>
            </a>
          </div>
          <a
            href="https://buy.stripe.com/28o5kL4Z57ZO1YA7st"
            target="_blank"
            rel="noreferrer"
            className="md:hidden mt-6 text-white font-medium bg-white/10 hover:bg-white/20 px-8 py-4 rounded-xl flex items-center gap-2 transition-colors"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth="2"
              stroke="currentColor"
              className="w-5 h-5"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M15.75 10.5V6a3.75 3.75 0 1 0-7.5 0v4.5m11.356-1.993 1.263 12c.07.665-.45 1.243-1.119 1.243H4.25a1.125 1.125 0 0 1-1.12-1.243l1.264-12A1.125 1.125 0 0 1 5.513 7.5h12.974c.576 0 1.059.435 1.119 1.007ZM8.625 10.5a.375.375 0 1 1-.75 0 .375.375 0 0 1 .75 0Zm7.5 0a.375.375 0 1 1-.75 0 .375.375 0 0 1 .75 0Z"
              />
            </svg>
            Get NFC Tags (50% off) &rarr;
          </a>
        </div>
        <div className="flex flex-row justify-center text-2xl w-full mt-10">
          ⭐️ ⭐️ ⭐️ ⭐️ ⭐️
        </div>
        <div className="flex justify-center mt-12">
          <img src={app} className="w-2/3 md:w-2/5" alt="app" />
        </div>
      </div>
    </div>
  );
}

export default Hero;
