import React from "react";
import { Link } from "react-router-dom";
import logo from "./appname.png";

function Header() {
  return (
    <>
      <div className="w-full bg-yellow-400 py-4 text-center">
        <p className="text-black font-medium">
          🎉 Launch offer: Get 50% off NFC tags
        </p>
      </div>
      <div className="w-full flex max-w-6xl flex-col md:flex-row justify-between items-center pt-10 md:px-10 lg:px-0">
        <div className="">
          <Link to="/">
            <img src={logo} className="h-3" alt="logo" />
          </Link>
        </div>
        <span className="hidden md:visible text-white text-md bg-white bg-opacity-5 p-4 rounded-2xl md:flex flex-row justify-center mt-8 md:mt-0">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke-width="1.5"
            stroke="currentColor"
            class="size-6 mr-2"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              d="M2.25 3h1.386c.51 0 .955.343 1.087.835l.383 1.437M7.5 14.25a3 3 0 0 0-3 3h15.75m-12.75-3h11.218c1.121-2.3 2.1-4.684 2.924-7.138a60.114 60.114 0 0 0-16.536-1.84M7.5 14.25 5.106 5.272M6 20.25a.75.75 0 1 1-1.5 0 .75.75 0 0 1 1.5 0Zm12.75 0a.75.75 0 1 1-1.5 0 .75.75 0 0 1 1.5 0Z"
            />
          </svg>
          <a
            href="https://buy.stripe.com/28o5kL4Z57ZO1YA7st"
            target="_blank"
            className="text-white cursor-pointer"
          >
            Buy NFC Tags &rarr;
          </a>
        </span>
      </div>
    </>
  );
}

export default Header;
