import React from "react";
import { Routes, Route } from "react-router-dom";
import "./App.css";
import Header from "./Header";
import Hero from "./Hero";
import Features from "./Features";
import Comparison from "./Comparison";
import Tag from "./Tag";
import Footer from "./Footer";
import PrivacyPolicy from "./components/PrivacyPolicy";

function App() {
  return (
    <div className="App flex flex-col align-middle items-center min-h-screen">
      <Header />
      <Routes>
        <Route path="/privacy" element={<PrivacyPolicy />} />
        <Route
          path="/"
          element={
            <>
              <Hero />
              <Features />
              <Tag />
              <Comparison />
            </>
          }
        />
      </Routes>
      <Footer />
    </div>
  );
}

export default App;
