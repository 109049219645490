import tag from "./tag.jpg";

function Tag() {
  return (
    <div className="flex flex-col max-w-5xl w-full px-10 pt-16 pb-4 border-t border-solid border-white border-opacity-10">
      <h2 className="text-white text-center text-3xl font-bold mb-8">
        One Tap to{" "}
        <span className="md:hidden">
          <br />
        </span>
        <span className="text-yellow-400">Total Focus</span>
      </h2>
      <div className="flex flex-col md:flex-row-reverse">
        <div className="flex flex-col w-full md:w-3/5">
          <p className="text-white mb-6 text-lg">
            Elevate your focus with the focusmode
            <span className="text-yellow-400">2</span> NFC Tag, a simple yet
            powerful tool designed to make staying productive easier and more
            intentional. Placing the tag in key locations like your workspace or
            study area creates a physical ritual for enabling Focus Mode,
            helping you commit to being distraction-free with a single tap.
          </p>
          <p className="text-white text-lg">
            It’s a small investment that pays off by streamlining your focus
            sessions and reduces the temptation to check apps. The tag works
            seamlessly with the app but is entirely optional — if you’re looking
            for an extra layer of accountability and structure, it’s a powerful
            addition to your productivity toolkit.
          </p>
          <span className="text-white bg-white bg-opacity-5 px-6 py-4 text-md rounded-2xl flex flex-row w-full md:w-56 mt-10 items-center justify-center">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke-width="1.5"
              stroke="currentColor"
              class="size-6 mr-2"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="M2.25 3h1.386c.51 0 .955.343 1.087.835l.383 1.437M7.5 14.25a3 3 0 0 0-3 3h15.75m-12.75-3h11.218c1.121-2.3 2.1-4.684 2.924-7.138a60.114 60.114 0 0 0-16.536-1.84M7.5 14.25 5.106 5.272M6 20.25a.75.75 0 1 1-1.5 0 .75.75 0 0 1 1.5 0Zm12.75 0a.75.75 0 1 1-1.5 0 .75.75 0 0 1 1.5 0Z"
              />
            </svg>
            <a
              href="https://buy.stripe.com/28o5kL4Z57ZO1YA7st"
              target="_blank"
              className="text-white cursor-pointer text-lg"
            >
              Buy NFC Tags &rarr;
            </a>
          </span>
        </div>
        <div className="flex flex-col w-full md:w-2/5 items-center relative mt-10 md:mt-2 mr-8">
          <span className="">
            <img src={tag} width="350" height="350" className="rounded-xl" />
          </span>
        </div>
      </div>
    </div>
  );
}

export default Tag;
